.buttons__text {
  border-radius: 10px !important;
  /* background-color: #0ece9b !important; */
  width: 180px !important;
  /* width: auto !important; */
  padding: 15px 30px !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.btn-bg-color{
  background-color: #fff;
}