.fade {
  /* height: 100% !important; */
  /* width: 100% !important; */
  width: 100% !important;
}
.fade > .modal-dialog > .modal-content {
  width: 100% !important;
  /* position: absolute;
    left: 60%; */
  /* transform: translate(55%); */
}
@media only screen and (max-width: 400px) {
  .fade > .modal-dialog > .modal-content {
    /* width:343px !important */
    /* width: 100% !important; */
  }
}
@media screen and (max-width: 2000px) and (min-width: 900px) {
  .fade > .modal-dialog > .modal-content {
    /* width: 700px !important; */
  }
}
.dropdown-toggle-split > div {
  margin: auto;
}

.forbackgroundcolor {
  background-color: #112564 !important;
  color: white !important;
}
.material-table-parent-div > div {
  font-size: 12px;
}

.fade > .modal-dialog {
  margin: auto !important;
}

@media only screen and (max-width: 400px) {
  .widthcontroling {
    /* width:343px !important */
    width: 50% !important;
  }
}
@media screen and (max-width: 2000px) and (min-width: 900px) {
  .widthcontroling {
    width: 300px !important;
  }
}

.fortextsize {
  font-size: 12px !important;
}

@media screen and (max-width: 450px) {
  .logosize {
    width: 90px;
  }
}
.buttons > button {
  margin-right: 18px !important;
  background-color: green;
}
.buttons > button {
  margin-right: 18px !important;
  background-color: green;
  color: white;
  width: 110px !important;
}
@media screen and (max-width: 450px) {
  .buttons > button {
    margin-right: 0px !important;
  }
}
.Discardbutton > button {
  margin-right: 18px !important;
  background-color: #d9534f;
  color: white;

  width: 110px !important;
}
.Discardbutton  :hover{
  /* margin-right: 18px !important; */
  background-color: #d9534f !important;
  color: white;

  
}
@media screen and (max-width: 450px) {
  .Discardbutton > button {
    margin-right: 0px !important;
  }
}

.forFontSize {
  font-size: 12px !important;
}

.comp-non-comp-heading{
  font-size: 15px;
  font-weight: bold;
}


.fortextsize > div > div > label > span {
  font-size: 12px !important;
}


.card-box img {
  position: absolute;
  /* bottom: -3px; */
  bottom: 0px;
  left: -10px;
  width: inherit;
}

@media screen and (max-width: 450px) {
  .card-box img {
    display: none;
  }
  .mobile-screen-h5{
    font-size: 14px !important;
  }
  .material-table-parent-div >div>table>tfoot>tr>td{
    width: 340px!important;
  }
}



@media screen and (max-width:1200px) {
  .mediumscreen {
    /* display: none;
     */
     width:100%!important;
  }
  
}