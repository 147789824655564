* {
  margin: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaedf7 !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  padding-right: 0% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button > .MuiIconButton-label > .material-icons {
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  color: #a1a5b7;
  background-color: #f5f8fa;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.3em + 1rem + 1px);
  width: calc(1.3em + 1rem + 1px);
  outline: none;
  font-size: 1.2rem;
  border-radius: 0.325rem;
}
button > .MuiIconButton-label > .material-icons:hover {
  color: #009ef7;
}

.MuiIconButton-root:hover {
  background: none !important;
  background-color: none !important;
}

.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span {
  background-color: #e8fff3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span:hover {
  background-color: #c9ffe3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span {
  background-color: #fff5f8 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span:hover {
  background-color: #ffd6e3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span {
  background-color: #f1faff !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span:hover {
  background-color: #f1faff !important;
  color: #009ef7;
  border-radius: 50%;
}

.card-dashboard {
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  border: 1px solid #e8e8f7;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  cursor: pointer;
  min-height: 200px;
}

.card-dashboard:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  transition-duration: 0.3s;
}
.custom-card {
  border: 0;
  box-shadow: -7.829px 11.607px 20px 0 #e4e3f5;
  /* margin-bottom: 20px; */
  cursor: pointer;
}
.custom-card {
  box-shadow: 0 10px 30px 0 rgba(98, 89, 202, 0.05);
}

.custom-card > a {
  cursor: pointer !important;

  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.custom-card > div {
  cursor: pointer;
}
.custom-card > div > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.custom-card a > div > div > label {
  cursor: pointer !important;
}
.custom-card > div > div > label > h5 {
  cursor: pointer !important;
}
.custom-card > div > div > label > img {
  cursor: pointer !important;
}

.mobile-responsive-width {
  width: 50%;
}

@media screen and (max-width: 450px) {
  .custom-card {
    border: 0;
    box-shadow: -7.829px 11.607px 20px 0 #e4e3f5;
    /* margin-bottom: 10px; */
    cursor: pointer;
  }
  .mobile-responsive-width {
    width: 100%;
    font-size: small;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 400px) {
  .container-fluid .p-lg-4 > {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
