.forborder {
    border: 0px;
  }
  .coloring {
    background-color: #002564 !important;
    color: white !important;
    
  }
  

  @media screen and (max-width: 450px) {
    .coloring {
      background-color: #002564 !important;
      

    }
   
  }

 
  @media screen and (max-width: 2000px) and (min-width: 900px) {
    .drawerTogglButton{
      /* width: 300px !important; */
      /* display: none !important; */
      color: aliceblue !important;
    }
  }
  /* .dashboard-btn :nth-child(1):hover {
      background-color: #002564 !important;
      text-decoration: none;
      margin-left: 3px;
      color: white;
  } */
  
  .dashboard-btn3:hover {
    background-color: #002564 !important;
    text-decoration: none;
    margin-left: 25px;
    color: white;
    transition: all 1s ease-in-out;
  }
  
  .btn-hover:hover {
    color: white;
  }
  
  .dashboard-btn3 > div > :hover {
    background-color: #002564 !important;
    text-decoration: none;
    color: white;
  }
  /* .dashboard-btn div:nth-child(2) :hover {
    background-color: #002564 !important;
    text-decoration: none;
    color: white;
  } */
  
  .marginecommercebutton {
    margin-left: 5px;
  }
  
  .analytic-btn :hover {
    /* background-color: #D0D0D0 !important;  */
    margin-left: 3px;
  }
  
  .addbutton :hover {
    cursor: pointer;
  }
  
  .check {
    width: 10px;
    height: 10px;
    color: red;
    position: relative;
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
  
  @keyframes example {
    0% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    25% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    50% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    75% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    100% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .logosize {
      width: 90px;
    }
    .forborder {
      background-color: #002564 !important;
    }
  }
  
  @media screen and (max-width: 992px) {
    .dropdownsize {
      margin-left: 92px;
      margin: 70px 0px 0px 100px !important;
    }
  }
  @media screen and (max-width: 570px) and (min-width: 370px) {
    .dropdownsizesmaller {
      margin: 0px;
      width: 250px !important;
    }
  }
  .drawerbackground {
    background-color: #002564 !important;
  }
  
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: #002564 !important;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    .drawer {
      background-color: green !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .onsmallscreen {
      display: none !important;
    }
  }