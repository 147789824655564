* {
  margin: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaedf7 !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  padding-right: 0% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button > .MuiIconButton-label > .material-icons {
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  color: #a1a5b7;
  background-color: #f5f8fa;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.3em + 1rem + 1px);
  width: calc(1.3em + 1rem + 1px);
  outline: none;
  font-size: 1.2rem;
  border-radius: 0.325rem;
}
button > .MuiIconButton-label > .material-icons:hover {
  color: #009ef7;
}

.MuiIconButton-root:hover {
  background: none !important;
  background-color: none !important;
}

.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span {
  background-color: #e8fff3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span:hover {
  background-color: #c9ffe3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span {
  background-color: #fff5f8 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span:hover {
  background-color: #ffd6e3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span {
  background-color: #f1faff !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span:hover {
  background-color: #f1faff !important;
  color: #009ef7;
  border-radius: 50%;
}

.card-dashboard {
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  border: 1px solid #e8e8f7;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  cursor: pointer;
  min-height: 200px;
}

.card-dashboard:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  transition-duration: 0.3s;
}
.custom-card {
  border: 0;
  box-shadow: -7.829px 11.607px 20px 0 #e4e3f5;
  /* margin-bottom: 20px; */
  cursor: pointer;
}
.custom-card {
  box-shadow: 0 10px 30px 0 rgba(98, 89, 202, 0.05);
}

.custom-card > a {
  cursor: pointer !important;

  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.custom-card > div {
  cursor: pointer;
}
.custom-card > div > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.custom-card a > div > div > label {
  cursor: pointer !important;
}
.custom-card > div > div > label > h5 {
  cursor: pointer !important;
}
.custom-card > div > div > label > img {
  cursor: pointer !important;
}

.mobile-responsive-width {
  width: 50%;
}

@media screen and (max-width: 450px) {
  .custom-card {
    border: 0;
    box-shadow: -7.829px 11.607px 20px 0 #e4e3f5;
    /* margin-bottom: 10px; */
    cursor: pointer;
  }
  .mobile-responsive-width {
    width: 100%;
    font-size: small;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 400px) {
  .container-fluid .p-lg-4 > {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.fade {
  /* height: 100% !important; */
  /* width: 100% !important; */
  width: 100% !important;
}
.fade > .modal-dialog > .modal-content {
  width: 100% !important;
  /* position: absolute;
    left: 60%; */
  /* transform: translate(55%); */
}
@media only screen and (max-width: 400px) {
  .fade > .modal-dialog > .modal-content {
    /* width:343px !important */
    /* width: 100% !important; */
  }
}
@media screen and (max-width: 2000px) and (min-width: 900px) {
  .fade > .modal-dialog > .modal-content {
    /* width: 700px !important; */
  }
}
.dropdown-toggle-split > div {
  margin: auto;
}

.forbackgroundcolor {
  background-color: #112564 !important;
  color: white !important;
}
.material-table-parent-div > div {
  font-size: 12px;
}

.fade > .modal-dialog {
  margin: auto !important;
}

@media only screen and (max-width: 400px) {
  .widthcontroling {
    /* width:343px !important */
    width: 50% !important;
  }
}
@media screen and (max-width: 2000px) and (min-width: 900px) {
  .widthcontroling {
    width: 300px !important;
  }
}

.fortextsize {
  font-size: 12px !important;
}

@media screen and (max-width: 450px) {
  .logosize {
    width: 90px;
  }
}
.buttons > button {
  margin-right: 18px !important;
  background-color: green;
}
.buttons > button {
  margin-right: 18px !important;
  background-color: green;
  color: white;
  width: 110px !important;
}
@media screen and (max-width: 450px) {
  .buttons > button {
    margin-right: 0px !important;
  }
}
.Discardbutton > button {
  margin-right: 18px !important;
  background-color: #d9534f;
  color: white;

  width: 110px !important;
}
.Discardbutton  :hover{
  /* margin-right: 18px !important; */
  background-color: #d9534f !important;
  color: white;

  
}
@media screen and (max-width: 450px) {
  .Discardbutton > button {
    margin-right: 0px !important;
  }
}

.forFontSize {
  font-size: 12px !important;
}

.comp-non-comp-heading{
  font-size: 15px;
  font-weight: bold;
}


.fortextsize > div > div > label > span {
  font-size: 12px !important;
}


.card-box img {
  position: absolute;
  /* bottom: -3px; */
  bottom: 0px;
  left: -10px;
  width: inherit;
}

@media screen and (max-width: 450px) {
  .card-box img {
    display: none;
  }
  .mobile-screen-h5{
    font-size: 14px !important;
  }
  .material-table-parent-div >div>table>tfoot>tr>td{
    width: 340px!important;
  }
}



@media screen and (max-width:1200px) {
  .mediumscreen {
    /* display: none;
     */
     width:100%!important;
  }
  
}
.legend-bg {
  background-color: #d0d0e6;
  border-radius: 12px;
}
.legend-bg-2 {
  background-color: #3b7c7c;
  border-radius: 12px;
}
.sainme > div > div {
  background-color: #3b7c7c !important;
}

.section_our_solution .row {
  align-items: center;
}

.our_solution_category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.our_solution_category .solution_cards_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.solution_cards_box .solution_card {
  flex: 0 1 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border-radius: 15px;
  margin: 8px;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 265px;
  transition: 0.7s;
}

/* .solution_cards_box .solution_card:hover { */
.solution_cards_box .solution_card {
  background: #112564;
  color: #fff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  /* -ms-transform-origin-x:inherit; */
  /* -webkit-transform: scale(1.); */
  z-index: 9;
}

.solution_cards_box .solution_card::before {
  background: rgb(85 108 214 / 10%);
}

.solution_cards_box .solution_card .solu_title h3,
.solution_cards_box .solution_card .solu_description p {
  color: #fff;
}

.solution_cards_box .solution_card:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 170px;
  height: 400px;
  z-index: -1;
  -webkit-transform: rotate(42deg);
          transform: rotate(42deg);
  right: -56px;
  top: -23px;
  border-radius: 35px;
}

.solution_cards_box .solution_card .solu_description button {
  background: #fff !important;
  color: #309df0;
}

.solution_card .so_top_icon {
}

.solution_card .solu_title h3 {
  color: #212121;
  font-size: 1.3rem;
  margin-top: 13px;
  margin-bottom: 13px;
}

.solution_card .solu_description p {
  font-size: 15px;
  margin-bottom: 15px;
}

.solution_card .solu_description button {
  border: 0;
  border-radius: 15px;
  background: linear-gradient(
    140deg,
    #42c3ca 0%,
    #42c3ca 50%,
    #42c3cac7 75%
  ) !important;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 16px;
}

.our_solution_content h1 {
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}
.our_solution_content p {
}

.hover_color_bubble {
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  -webkit-transform: rotate(-36deg);
          transform: rotate(-36deg);
  left: -18rem;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover .hover_color_bubble {
  top: 0rem;
}

.solution_cards_box .solution_card .so_top_icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution_cards_box .solution_card .so_top_icon img {
  width: 40px;
  height: 50px;
  object-fit: contain;
}

/*start media query*/
@media screen and (min-width: 320px) {
  .sol_card_top_3 {
    position: relative;
    top: 0;
  }

  .our_solution_category {
    width: 100%;
    margin: 0 auto;
  }

  .our_solution_category .solution_cards_box {
    flex: auto;
  }
}
@media only screen and (min-width: 768px) {
  .our_solution_category .solution_cards_box {
    flex: 1 1;
  }
}
@media only screen and (min-width: 1024px) {
  .sol_card_top_3 {
    position: relative;
    top: -3rem;
  }
  .our_solution_category {
    width: 50%;
    margin: 0 auto;
  }
}
.saime {
  border: none !important;
}

/* .forpopup >div>div{
    text-align: center !important;
} */

@media only screen and (min-width: 1024px) {
  .forpopup > div > div {
    text-align: right;
  }
}

@media screen and (min-width: 320px) {
  .forpopup > div > button {
    text-align: center;
  }
}
.forremovmargin > div {
  margin: 0% !important;
}

.forborder {
    border: 0px;
  }
  .coloring {
    background-color: #002564 !important;
    color: white !important;
    
  }
  

  @media screen and (max-width: 450px) {
    .coloring {
      background-color: #002564 !important;
      

    }
   
  }

 
  @media screen and (max-width: 2000px) and (min-width: 900px) {
    .drawerTogglButton{
      /* width: 300px !important; */
      /* display: none !important; */
      color: aliceblue !important;
    }
  }
  /* .dashboard-btn :nth-child(1):hover {
      background-color: #002564 !important;
      text-decoration: none;
      margin-left: 3px;
      color: white;
  } */
  
  .dashboard-btn3:hover {
    background-color: #002564 !important;
    text-decoration: none;
    margin-left: 25px;
    color: white;
    transition: all 1s ease-in-out;
  }
  
  .btn-hover:hover {
    color: white;
  }
  
  .dashboard-btn3 > div > :hover {
    background-color: #002564 !important;
    text-decoration: none;
    color: white;
  }
  /* .dashboard-btn div:nth-child(2) :hover {
    background-color: #002564 !important;
    text-decoration: none;
    color: white;
  } */
  
  .marginecommercebutton {
    margin-left: 5px;
  }
  
  .analytic-btn :hover {
    /* background-color: #D0D0D0 !important;  */
    margin-left: 3px;
  }
  
  .addbutton :hover {
    cursor: pointer;
  }
  
  .check {
    width: 10px;
    height: 10px;
    color: red;
    position: relative;
    -webkit-animation-name: example;
            animation-name: example;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  @-webkit-keyframes example {
    0% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    25% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    50% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    75% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    100% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
  }
  
  @keyframes example {
    0% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    25% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    50% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    75% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
    100% {
      color: #002564;
      left: 0px;
      top: 0px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .logosize {
      width: 90px;
    }
    .forborder {
      background-color: #002564 !important;
    }
  }
  
  @media screen and (max-width: 992px) {
    .dropdownsize {
      margin-left: 92px;
      margin: 70px 0px 0px 100px !important;
    }
  }
  @media screen and (max-width: 570px) and (min-width: 370px) {
    .dropdownsizesmaller {
      margin: 0px;
      width: 250px !important;
    }
  }
  .drawerbackground {
    background-color: #002564 !important;
  }
  
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: #002564 !important;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    .drawer {
      background-color: green !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .onsmallscreen {
      display: none !important;
    }
  }
.buttons__card {
  border-radius: 5px;
  box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%) !important;
  border: none !important;
  padding: 10px 0px;
  /* height: 100px; */
}

.icons__button {
  border-radius: 10px !important;
  padding: 15px 30px !important;
  background-color: #e0e1ff;
  color: #151aa6 !important;
  /* font-size: 18px !important; */
}

.legend__box {
  background-color: #e0e1ff;
  border-radius: 10px !important;
}

.btn__active {
  border-radius: 10px !important;
  background-color: blue !important;
  width: 150px !important;
  padding: 15px 30px !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

@media only screen and (max-width: 450px) {
  .buttons__text {
    border-radius: 10px !important;
    width: 110px !important;
    padding: 10px 1px !important;
    border: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .routing > .container-fluid > {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.buttons__text {
  border-radius: 10px !important;
  /* background-color: #0ece9b !important; */
  width: 180px !important;
  /* width: auto !important; */
  padding: 15px 30px !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.btn-bg-color{
  background-color: #fff;
}
.header__card {
  border-radius: 5px;
  box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%) !important;
  border: none !important;
  padding: 10px 20px;
  /* height: 100px; */
  
}


