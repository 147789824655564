.buttons__card {
  border-radius: 5px;
  box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%) !important;
  border: none !important;
  padding: 10px 0px;
  /* height: 100px; */
}

.icons__button {
  border-radius: 10px !important;
  padding: 15px 30px !important;
  background-color: #e0e1ff;
  color: #151aa6 !important;
  /* font-size: 18px !important; */
}

.legend__box {
  background-color: #e0e1ff;
  border-radius: 10px !important;
}

.btn__active {
  border-radius: 10px !important;
  background-color: blue !important;
  width: 150px !important;
  padding: 15px 30px !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

@media only screen and (max-width: 450px) {
  .buttons__text {
    border-radius: 10px !important;
    width: 110px !important;
    padding: 10px 1px !important;
    border: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .routing > .container-fluid > {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
